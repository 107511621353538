import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import SetBodyColor from "./SetBodyColor";
import { Helmet } from "react-helmet";

export default function Header() {
  const Site_Url = "https://rajasthangazette.co.in/";
  const BASE_URL = Site_Url + "admin/API/";

  //website Setting API
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  SetBodyColor({ color: `${webSetting.siteColor}` });

  const [languagedata, setlanguagedata] = useState([]);
  useEffect(() => {
    const Alllanguagedata = async () => {
      const response = await fetch(`${BASE_URL}Alllanguagedata.php`);
      const jsonData = await response.json();
      setlanguagedata(jsonData);
    };
    Alllanguagedata();
  }, [BASE_URL]);

  return (
    <>
      <Helmet>
        <link rel="icon" type="image/x-icon" href={webSetting.favicon} />
      </Helmet>
      <div className="top_header">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-6 col-sm-12">
              <div className="top_left_contact">
                <a href="/">
                  <i className="fa fa-envelope-o" aria-hidden="true"></i>
                  {webSetting.email}
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="top_right_btn">
                <a href="/">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  {webSetting.number}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-dark sticky-top header">
        <div className="container">
          <NavLink className="navbar-brand brand" to="/">
            <span class="logo_text_name">rajasthan</span>
            <p className="brand_text_area">
              {/* <span className="logo_text_change">gaze</span> */}
              gazette
            </p>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="toggle_btn">
              <i className="fa fa-bars" aria-hidden="true"></i>
            </span>
          </button>
          <div
            className="collapse navbar-collapse header_menu"
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink exact="true" className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact="true" className="nav-link" to="/about">
                  About
                </NavLink>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link BlogDropbtn"
                  data-bs-toggle="dropdown"
                >
                  Blog
                </Link>
                <ul className="dropdown-menu BlogDropbtnMenu">
                  {languagedata.map((value, index) => {
                    return (
                      <li key={index}>
                        <NavLink
                          className="BlogDropbtnlink dropdown-item"
                          to={`/blog/${value.language}`}
                        >
                          {" "}
                          {value.long_title}{" "}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </li>

              <li className="nav-item">
                <NavLink exact="true" className="nav-link" to="/services">
                  Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact="true" className="nav-link" to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
